'use client'

import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Button } from "../components/ui/button"
import { LayoutDashboard, UserPlus, BookPlus, FileBarChart, ClipboardList, LogOut, ChevronLeft, ChevronRight } from 'lucide-react'

const sidebarItems = [
  { icon: LayoutDashboard, label: 'Dashboard', href: '/dashboard' },
  { icon: BookPlus, label: 'Add Subject', href: '/AddSubject' },
  { icon: ClipboardList, label: 'Class Management', href: '/ClassManagement' },
  { icon: BookPlus, label: 'Course Management', href: '/CourseManagement' },
  { icon: FileBarChart, label: 'Monthly Report', href: '/MonthlyReport' },
  { icon: UserPlus, label: 'Student Management', href: '/StudentManagement' },
  { icon: ClipboardList, label: 'Update Marks', href: '/StudentReportCardForm' },
]

export function SideNavbar({ handleLogout }) { // Add handleLogout prop
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AnimatePresence initial={false}>
      <motion.aside
        initial={{ width: isOpen ? 240 : 80 }}
        animate={{ width: isOpen ? 240 : 80 }}
        exit={{ width: 80 }}
        className="bg-red-600 text-white p-4 flex flex-col h-screen"
      >
        <div className="flex items-center justify-between mb-8">
          <motion.div animate={{ scale: isOpen ? 1 : 0.8 }}>
            <img
              src="/logo.png"
              alt="Fit Logo"
              className="w-auto h-20"
            />
          </motion.div>
          <Button variant="ghost" size="icon" onClick={handleToggle} className="text-white hover:bg-red-700">
            {isOpen ? <ChevronLeft /> : <ChevronRight />}
          </Button>
        </div>
        <nav className="flex-1">
          <ul className="space-y-2">
            {sidebarItems.map((item, index) => (
              <motion.li
                key={index}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Button variant="ghost" className="w-full justify-start text-white hover:bg-red-700" asChild>
                  <a href={item.href}>
                    <item.icon className="mr-2" />
                    <AnimatePresence>
                      {isOpen && (
                        <motion.span
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          {item.label}
                        </motion.span>
                      )}
                    </AnimatePresence>
                  </a>
                </Button>
              </motion.li>
            ))}
          </ul>
        </nav>
        {/* Updated Logout Button */}
        <Button
          variant="ghost"
          className="mt-auto text-white hover:bg-red-700"
          onClick={handleLogout} // Trigger the logout function directly
        >
          <LogOut className="mr-2" />
          {isOpen && 'Logout'}
        </Button>
      </motion.aside>
    </AnimatePresence>
  )
}

export default SideNavbar;
