// src/theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#c24246", // Primary color
      dark: "#a73136", // Darker shade of primary
      light: "#f8d7da", // Lighter shade of primary
      contrastText: "#ffffff", // Text color on primary color
    },
    secondary: {
      main: "#53BDF5", // Secondary color
      dark: "#1A73E8", // Darker shade of secondary
      light: "#D6EBFF", // Lighter shade of secondary
      contrastText: "#ffffff", // Text color on secondary color
    },
    background: {
      default: "#fbf9fa", // Light background color
      paper: "#ffffff", // Color for paper backgrounds
    },
    text: {
      primary: "#001926", // Text color
      secondary: "#FFD7D7", // Secondary text color
    },
  },
});

export default theme;
