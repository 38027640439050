// App.js
import React, { Suspense, lazy, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import SideNavbar from "./component/SideNavbar"; // Import the sidebar component
import "./App.css"; // Add custom styles here

const Dashboard = lazy(() => import("./component/Dashboard"));
const LoginPage = lazy(() => import("./component/LoginPage"));
const AddStudent = lazy(() => import("./Pages/AddStudent"));
const AddSubject = lazy(() => import("./Pages/AddSubject"));
const MonthlyReport = lazy(() => import("./Pages/MonthlyReport"));
const WeeklyReport = lazy(() => import("./Pages/WeeklyReport"));
const StudentManagement = lazy(() => import("./Pages/StudentManagement"));
const ClassManagement = lazy(() => import("./Pages/ClassManagement"));
const CourseManagement = lazy(() => import("./Pages/CourseManagement"));
const StudentReportCardForm = lazy(() =>
  import("./Pages/StudentReportCardForm")
);
const ViewStudent = lazy(() => import("./Pages/ViewStudent"));
const ViewSubject = lazy(() => import("./Pages/ViewSubject"));

// ProtectedRoute component
function ProtectedRoute({ children, token }) {
  const location = useLocation();
  return token ? (
    children
  ) : (
    <Navigate to="/" replace state={{ from: location }} />
  );
}

function App() {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    setToken(authToken);
    setLoading(false); // Set loading to false after token check
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  return (
    <Router>
      <div className="flex h-screen">
        {/* Render sidebar only if the user is authenticated */}
        {token && <SideNavbar className="w-0 fixed left-0 h-full" />}

        {/* Main content area */}
        <div className={`flex-1 ${token ? "ml-64" : ""} overflow-auto`}>
          <Suspense
            fallback={
              <div className="flex justify-center items-center h-screen bg-gray-100">
                <div className="loader">Loading...</div>
              </div>
            }
          >
            <Routes>
              {/* Redirect to dashboard if token exists and user is on the login page */}
              <Route
                path="/"
                element={token ? <Navigate to="/dashboard" /> : <LoginPage />}
              />

              {/* Protected routes */}
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute token={token}>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/addStudent"
                element={
                  <ProtectedRoute token={token}>
                    <AddStudent />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/addSubject"
                element={
                  <ProtectedRoute token={token}>
                    <AddSubject />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/monthlyReport"
                element={
                  <ProtectedRoute token={token}>
                    <MonthlyReport />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/weeklyReport"
                element={
                  <ProtectedRoute token={token}>
                    <WeeklyReport />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/studentReportCardForm"
                element={
                  <ProtectedRoute token={token}>
                    <StudentReportCardForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/viewStudent"
                element={
                  <ProtectedRoute token={token}>
                    <ViewStudent />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/viewSubject"
                element={
                  <ProtectedRoute token={token}>
                    <ViewSubject />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/CourseManagement"
                element={
                  <ProtectedRoute token={token}>
                    <CourseManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/ClassManagement"
                element={
                  <ProtectedRoute token={token}>
                    <ClassManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/StudentManagement"
                element={
                  <ProtectedRoute token={token}>
                    <StudentManagement />
                  </ProtectedRoute>
                }
              />

              {/* Fallback to login page for undefined routes */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
        </div>
      </div>
    </Router>
  );
}

export default App;
